._styles__backgroud-video__16dW5 {
  background: linear-gradient(
    3deg,
    rgb(0, 0, 0) 0%,
    rgb(3, 105, 195) 51%,
    rgba(72, 120, 163, 1) 100%
  );
}

._styles__colorInitial__2EvBp {
  color: rgba(72, 120, 163, 1);
}

._styles__callBox__iIb9i {
  margin-top: 6%;
  margin-bottom: 6%;
  align-items: center;
  justify-content: center;
  display: flex;
}

._styles__callContent__3Pd_B {
  padding: 5%;
  background-color: white;
  width: 50%;
  border-radius: 25px;
  box-shadow: 0 0.46875rem 2.1875rem rgba(90, 97, 105, 0.1),
    0 0.9375rem 1.40625rem rgba(90, 97, 105, 0.1),
    0 0.25rem 0.53125rem rgba(90, 97, 105, 0.12),
    0 0.125rem 0.1875rem rgba(90, 97, 105, 0.1);
}

._styles__btnHangOut__2GRf2 {
  margin: 2%;
  padding: 4%;
  width: 200px;
}

._styles__btnAnswer__1UTzj {
  margin: 2%;
  padding: 4%;
  width: 200px;
}

._styles__h60Vh__3X85C {
  height: 60vh;
}

._styles__counter__3rCtN {
  position: fixed;
  top: 40px;
  right: 30px;
  color: white;
  text-align: center;
}
._styles__fs50__T9Q5c {
  font-size: 50px;
}
._styles__fs16__qZqNu {
  font-size: 16px;
}

@media only screen and (max-width: 768px) {
  ._styles__callContent__3Pd_B {
    width: 100%;
  }
}

@media only screen and (max-width: 500px) {
  ._styles__smH100__OjItY {
    height: 100% !important;
  }
  ._styles__containerButtonsCall__3fBGs {
    position: fixed;
    height: 100px;
    bottom: 0;
    right: 0;
    left: 15px;
    width: 100%;
  }
  ._styles__remoteStream__2_Pti {
    position: fixed;
    top: 50px;
    left: 20px;
    height: 13vh;
    width: 10vh;
    padding: 0px;
  }
  ._styles__counter__3rCtN {
    position: fixed;
    top: 40px;
    right: 30px;
    color: white;
    text-align: center;
  }
  ._styles__fs50__T9Q5c {
    font-size: 50px;
  }
  ._styles__fs16__qZqNu {
    font-size: 16px;
  }
  ._styles__callBox__iIb9i {
    margin-top: 0%;
    margin-bottom: 0%;
  }
  ._styles__callContent__3Pd_B {
    margin: auto;
  }
}

/* add css module styles here (optional) */

._styles-module__test__3ybTi {
  margin: 2em;
  padding: 0.5em;
  border: 2px solid red;
  font-size: 2em;
  text-align: center;
}

body {
  color: #616161;
  background-color: transparent;
}

._styles-module__dot__3U2py {
  height: 55px;
  width: 55px;
  background-color: #4c95e9;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 16px;
  bottom: 0px;
  cursor: pointer;
}

._styles-module__button__2hTXI {
  background-color: #4c95e9;
}

._styles-module__containerMain__2fTaa {
  height: 600px;
  width: 350px;
  display: flex;
  position: absolute;
  right: 50px;
  bottom: 50px;
  flex-direction: column;
}

._styles-module__containerInfo__2klPP {
  flex: 7;
  box-shadow: 2px 2px 9px 0px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  /* border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px; */
}

._styles-module__loginHeader__ru7aH {
  background-color: #4c95e9;
  color: white;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

._styles-module__homeHeader__mVhcA {
  background-color: white;
  font-size: 12px;
  padding-top: 20px;
  padding-bottom: 8px;
  padding-left: 10px;
  padding-right: 10px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

h1 {
  font-size: 26px;
  font-weight: bold;
}

h2 {
  font-size: 14px;
  font-weight: normal;
  margin: 8px 0px;
}

p {
  font-size: 12px;
  font-weight: 300;
}

._styles-module__horizontalScrollable__xMxTm > ._styles-module__row__1iLpS {
  overflow-x: auto;
  white-space: nowrap;
}

._styles-module__horizontalScrollable__xMxTm > ._styles-module__row__1iLpS > ._styles-module__col__1KQtn {
  display: inline-block;
  float: none;
}

._styles-module__verticalScrollable__f3lw0 {
  max-height: 360px;
  overflow-y: scroll;
  padding-bottom: 10px;
}

@media (max-width: 450px) {
  ._styles-module__verticalScrollable__f3lw0 {
    max-height: 550px;
    overflow-y: scroll;
    padding-bottom: 10px;
  }
}

._styles-module__horizontalScrollContainer__2UDDq {
  width: auto;
  overflow-y: hidden;
  overflow-x: auto;
  padding: 10px 10px 10px 0px;
  white-space: nowrap;
}

a {
  -webkit-text-decoration-color: black;
          text-decoration-color: black;
  -webkit-text-decoration-line: none;
          text-decoration-line: none;
  -webkit-text-decoration-style: none;
          text-decoration-style: none;
  color: black;
}

a:hover {
  color: black;
  -webkit-text-decoration-line: none;
          text-decoration-line: none;
}

._styles-module__label__3sXmF {
  font-size: 12px;
  color: #8e8e8e;
}

._styles-module__horizontalScrollContainer__2UDDq > div {
  margin: 0 10px 0 0;
  padding: 2px 15px;
  background: #4c95e9;
  display: inline-block;
  text-align: center;
  color: white;
  border-radius: 15px;
}

._styles-module__containerBox__3MyX4 {
  display: flex;
  flex-direction: column;
  flex: 1;
}

._styles-module__containerHome__1Uvmf {
  display: flex;
  flex-direction: column;
  flex: 5;
  height: 100%;
  background-color: white;
  border-radius: 20px;
}

._styles-module__homeBody__1GzEJ {
  display: flex;
  flex-direction: column;
  flex: 1;
}

._styles-module__homeBodyContainer__1ennl {
  flex: 5;
  margin: 0px 10px;
}

._styles-module__homeBodyContainerNoAdvisor__15sx1 {
  display: flex;
  flex: 1;
  align-content: center;
  align-items: center;
  padding: 20px;
  text-align: center;
}

._styles-module__homeBodyFooter__3-7eJ {
  text-align: center;
  padding-bottom: 10px;
}

._styles-module__styleSmall__3QKw- {
  position: absolute;
  z-index: 100;
  right: 0%;
  top: 50%;
  transform: translate(0%, -50%);
  height: 70vh;
  background-color: rgba(43, 44, 47, 1);
}

._styles-module__styleFull__CPl6O {
  position: absolute;
  z-index: 100;
  right: 50%;
  top: 50%;
  transform: translate(50%, -50%);
  width: 80vw;
}

._styles-module__bgGray__3gIjy {
  background-color: rgb(43, 44, 47, 1);
}

._styles-module__bgBlack__3zQKu {
  background-color: rgba(0, 0, 0, 0);
}

._styles-module__videoContainer__2u6km {
  padding: 20px;
}

@media only screen and (max-width: 768px) {
  ._styles-module__dot__3U2py {
    position: fixed;
    bottom: 20px;
  }
}

@media only screen and (max-width: 500px) {
  ._styles-module__styleFull__CPl6O {
    position: fixed;
    z-index: 100;
    right: 0;
    top: 0;
    transform: translate(0%, 0%);
    height: 100%;
    width: 100%;
  }
  ._styles-module__videoContainer__2u6km {
    height: 100%;
    padding: 0px;
  }
  ._styles-module__containerMain__2fTaa {
    height: 95%;
    width: 90%;
    display: flex;
    position: fixed;
    right: 5%;
    bottom: 3%;
    flex-direction: column;
  }
}

._styles-module__hr-text__3w-mM {
  line-height: 1em;
  position: relative;
  outline: 0;
  border: 0;
  color: red;
  text-align: center;
  height: 1.5em;
  opacity: 0.5;
}

._styles-module__hr-text__3w-mM::before {
  content: '';
  background: linear-gradient(to right, transparent, #818078, transparent);
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  height: 1px;
}

._styles-module__hr-text__3w-mM::after {
  content: attr(data-content);
  position: relative;
  display: inline-block;
  color: red;
  padding: 0 0.5em;
  line-height: 1.5em;
  color: #818078;
  background-color: #fcfcfa;
}

/* Message */

._styles-module__containerMessageRight__1azeX {
  font-weight: 'normal';
  display: flex;
  color: white;
  justify-content: flex-end;
  align-items: flex-end;
  font-family: 'AvenirSemiBloc';
  padding: 10px;
}

._styles-module__containerMessageLeft__3t_UY {
  font-weight: 'normal';
  display: flex;
  color: #484879;
  justify-content: flex-start;
  align-items: flex-end;
  font-family: 'AvenirSemiBloc';
  padding: 10px;
}

._styles-module__messageRight__2Wsly {
  width: 80%;
  background-color: #0095ff;
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  padding: 8px 15px;
  margin-top: 5px;
}

._styles-module__messageRight__2Wsly p:first-letter {
  text-transform: capitalize;
}

._styles-module__messageRight__2Wsly p {
  margin: 0px;
}

._styles-module__firstCapitalize__1k8sf p::first-letter {
  text-transform: capitalize;
}

._styles-module__messageLeft__1hJU2 {
  width: 80%;
  background-color: #f7f7f7;
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  padding: 8px 15px;
  margin-top: 5px;
}

._styles-module__messageLeft__1hJU2 p:first-letter {
  text-transform: capitalize;
}

._styles-module__messageLeft__1hJU2 p {
  margin: 0px;
}
